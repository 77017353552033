import { Divider } from "antd";
import { ContactUs } from "../components/landingPage/contactUs";
import { Hero } from "../components/landingPage/hero";
import { Location } from "../components/landingPage/location";
import { Footer } from "../components/navigation/footer";
import { DynamicMetaTags } from "../utils/dynamicMetaTags";
import ogImage from "../images/ogImage.png";
import { DetailCards } from "../components/landingPage/detailCards";
import { MatterPort } from "../components/landingPage/matterPort";

export const LandingPage = ({ helloWorld }) => {
  return (
    <div style={{ justifyContent: "center", display: "flex" }}>
      <DynamicMetaTags ogTitle={`Storage.lk`} ogImage={ogImage} />
      <div style={{ maxWidth: "1200px" }}>
        <Hero helloWorld={helloWorld} />
        <Divider />
        <ContactUs />
        <Divider>Virtual Tour</Divider>
        <MatterPort />
        <Divider />
        <DetailCards />
        <Divider />
        <Location />
        <Footer />
      </div>
    </div>
  );
};
