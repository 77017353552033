import { Col, Row } from "antd";

export const StorageLayout = () => {
  return (
    <div className="main" style={{ margin: "10px" }}>
      <Row gutter={[18, 18]}>
        <Col span={24}>
          <h1>StorageLayout</h1>
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "50px",
                height: "50px",
                border: "1px solid green",
              }}
            >
              25sqft
            </div>
            <div
              style={{
                width: "50px",
                height: "50px",
                border: "1px solid green",
              }}
            >
              25sqft
            </div>
            <div
              style={{
                width: "50px",
                height: "50px",
                border: "1px solid green",
              }}
            >
              {" "}
              25sqft
            </div>
            <div
              style={{
                width: "50px",
                height: "50px",
                border: "1px solid green",
              }}
            >
              {" "}
              25sqft
            </div>
            <div
              style={{
                width: "50px",
                height: "50px",
                border: "1px solid green",
              }}
            >
              {" "}
              25sqft
            </div>
            <div
              style={{
                width: "50px",
                height: "50px",
                border: "1px solid green",
              }}
            >
              {" "}
              25sqft
            </div>
            <div
              style={{
                width: "50px",
                height: "50px",
                border: "1px solid green",
              }}
            >
              {" "}
              25sqft
            </div>
            <div
              style={{
                width: "50px",
                height: "50px",
                border: "1px solid green",
              }}
            >
              {" "}
              25sqft
            </div>
            <div
              style={{
                width: "50px",
                height: "50px",
                border: "1px solid green",
              }}
            >
              {" "}
              25sqft
            </div>
            <div
              style={{
                width: "100px",
                height: "50px",
                border: "1px solid green",
              }}
            >
              {" "}
              50sqft
            </div>
            <div
              style={{
                width: "50px",
                height: "50px",
                border: "1px solid green",
              }}
            >
              {" "}
              25sqft
            </div>
            <div
              style={{
                width: "50px",
                height: "50px",
                border: "1px solid green",
              }}
            >
              {" "}
              25sqft
            </div>
            <div
              style={{
                width: "50px",
                height: "50px",
                border: "1px solid green",
              }}
            >
              {" "}
              25sqft
            </div>
            <div
              style={{
                width: "100px",
                height: "50px",
                border: "1px solid green",
              }}
            >
              {" "}
              50sqft
            </div>
            <div
              style={{
                width: "50px",
                height: "50px",
                border: "1px solid green",
              }}
            >
              {" "}
              25sqft
            </div>
            <div
              style={{
                width: "50px",
                height: "50px",
                border: "1px solid green",
              }}
            >
              {" "}
              25sqft
            </div>
            <div
              style={{
                width: "50px",
                height: "50px",
                border: "1px solid green",
              }}
            >
              {" "}
              25sqft
            </div>
            <div
              style={{
                width: "50px",
                height: "50px",
                backgroundColor: "grey",
              }}
            >
              {" "}
            </div>
          </div>
          <div>
            <div
              style={{
                width: "1000px",
                height: "50px",
                border: "1px solid grey",
                backgroundColor: "grey",
              }}
            ></div>
          </div>
          <div style={{ display: "flex" }}>
            <div>
              <div
                style={{
                  width: "300px",
                  height: "50px",
                  border: "1px solid grey",
                  backgroundColor: "grey",
                }}
              ></div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    border: "1px solid green",
                  }}
                >
                  25sqft
                </div>
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    backgroundColor: "grey",
                  }}
                ></div>
                <div
                  style={{
                    width: "100px",
                    height: "50px",
                    border: "1px solid green",
                  }}
                >
                  {" "}
                  50sqft
                </div>
                <div
                  style={{
                    width: "100px",
                    height: "50px",
                    backgroundColor: "grey",
                  }}
                ></div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      border: "1px solid green",
                    }}
                  >
                    {" "}
                    25sqft
                  </div>
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      border: "1px solid green",
                    }}
                  >
                    {" "}
                    25sqft
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      backgroundColor: "grey",
                    }}
                  ></div>
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      backgroundColor: "grey",
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    width: "100px",
                    height: "100px",
                    border: "1px solid green",
                  }}
                >
                  {" "}
                  100sqft
                </div>
                <div
                  style={{
                    width: "100px",
                    height: "100px",
                    backgroundColor: "grey",
                  }}
                ></div>
              </div>
            </div>

            <div
              style={{
                width: "100px",
                height: "200px",
                border: "1px solid green",
              }}
            >
              200sqft
            </div>
            <div
              style={{
                width: "100px",
                height: "200px",
                border: "1px solid green",
              }}
            >
              {" "}
              200sqft
            </div>
            <div
              style={{
                width: "100px",
                height: "200px",
                border: "1px solid green",
                backgroundColor: "lime",
              }}
            >
              {" "}
              200sqft - VACANT
            </div>
            <div
              style={{
                width: "100px",
                height: "200px",
                border: "1px solid green",
              }}
            >
              {" "}
              200sqft
            </div>
            <div>
              <div
                style={{
                  width: "150px",
                  height: "100px",
                  border: "1px solid green",
                }}
              >
                150sqft
              </div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: "125px",
                    height: "100px",
                    border: "1px solid green",
                  }}
                >
                  100sqft
                </div>
                <div
                  style={{
                    width: "25px",
                    height: "100px",
                    backgroundColor: "grey",
                  }}
                ></div>
              </div>
            </div>
            <div
              style={{
                width: "100px",
                height: "200px",
                border: "1px solid grey",
                backgroundColor: "grey",
              }}
            ></div>
            <div>
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  backgroundColor: "grey",
                }}
              ></div>
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  border: "1px solid green",
                  backgroundColor: "red",
                }}
              >
                {" "}
                25sqft Arrears
              </div>
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  border: "1px solid green",
                }}
              >
                {" "}
                25sqft
              </div>
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  border: "1px solid green",
                }}
              >
                {" "}
                25sqft
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div>
              <div
                style={{
                  width: "200px",
                  height: "100px",
                  border: "1px solid green",
                }}
              >
                {" "}
                200sqft
              </div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: "200px",
                    height: "100px",
                    backgroundColor: "#d8ce27",
                  }}
                ></div>
              </div>
            </div>
            <div
              style={{
                width: "100px",
                height: "200px",
                backgroundColor: "grey",
              }}
            ></div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: "100px",
                  height: "200px",
                  border: "1px solid green",
                }}
              >
                {" "}
                200sqft
              </div>
              <div
                style={{
                  width: "100px",
                  height: "200px",
                  border: "1px solid green",
                }}
              >
                {" "}
                200sqft
              </div>
              <div
                style={{
                  width: "100px",
                  height: "200px",
                  border: "1px solid green",
                }}
              >
                {" "}
                200sqft
              </div>
              <div
                style={{
                  width: "100px",
                  height: "200px",
                  border: "1px solid green",
                }}
              >
                {" "}
                200sqft
              </div>
              <div
                style={{
                  width: "100px",
                  height: "200px",
                  border: "1px solid green",
                }}
              >
                {" "}
                200sqft
              </div>
              <div
                style={{
                  width: "100px",
                  height: "200px",
                  border: "1px solid green",
                }}
              >
                {" "}
                200sqft
              </div>
              <div>
                <div
                  style={{
                    width: "100px",
                    height: "100px",
                    border: "1px solid green",
                  }}
                >
                  {" "}
                  100sqft
                </div>
                <div
                  style={{
                    width: "100px",
                    height: "100px",
                    border: "1px solid green",
                  }}
                >
                  100sqft
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "200px",
                height: "50px",
                backgroundColor: "#d8ce27",
              }}
            ></div>
            <div
              style={{
                width: "800px",
                height: "50px",
                backgroundColor: "grey",
              }}
            ></div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "200px",
                height: "200px",
                backgroundColor: "#d8ce27",
              }}
            ></div>
            <div
              style={{
                width: "100px",
                height: "200px",
                backgroundColor: "grey",
              }}
            ></div>
            <div
              style={{
                width: "100px",
                height: "200px",
                border: "1px solid green",
              }}
            >
              {" "}
              200sqft
            </div>
            <div>
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  border: "1px solid green",
                }}
              >
                {" "}
                100sqft
              </div>
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  border: "1px solid green",
                }}
              >
                {" "}
                100sqft
              </div>
            </div>
            <div>
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  border: "1px solid green",
                }}
              >
                {" "}
                100sqft
              </div>
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  border: "1px solid green",
                }}
              >
                {" "}
                100sqft
              </div>
            </div>
            <div>
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  border: "1px solid green",
                }}
              >
                {" "}
                100sqft
              </div>
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  border: "1px solid green",
                }}
              >
                {" "}
                100sqft
              </div>
            </div>
            <div>
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  border: "1px solid green",
                  backgroundColor: "red",
                }}
              >
                {" "}
                100sqft Arrears
              </div>
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  border: "1px solid green",
                }}
              >
                {" "}
                100sqft
              </div>
            </div>
            <div>
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  border: "1px solid green",
                }}
              >
                {" "}
                100sqft
              </div>
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  border: "1px solid green",
                }}
              >
                {" "}
                100sqft
              </div>
            </div>
            <div>
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  border: "1px solid green",
                }}
              >
                {" "}
                100sqft
              </div>
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  border: "1px solid green",
                }}
              >
                {" "}
                100sqft
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "200px",
                height: "50px",
                backgroundColor: "#d8ce27",
              }}
            ></div>
            <div
              style={{
                width: "800px",
                height: "50px",
                backgroundColor: "grey",
              }}
            ></div>
          </div>
          {/* top section */}
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "100px",
                height: "100px",
                border: "1px solid green",
              }}
            >
              {" "}
              100sqft
            </div>
            <div>
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  border: "1px solid green",
                }}
              >
                {" "}
                25sqft
              </div>
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  backgroundColor: "grey",
                }}
              ></div>
            </div>
            <div>
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  border: "1px solid green",
                }}
              >
                {" "}
                25sqft
              </div>
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  backgroundColor: "grey",
                }}
              ></div>
            </div>
            <div
              style={{
                width: "166.667px",
                height: "100px",
                backgroundColor: "grey",
              }}
            ></div>
            <div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: "100px",
                    height: "50px",
                    border: "1px solid green",
                  }}
                >
                  50sqft
                </div>
                <div
                  style={{
                    width: "100px",
                    height: "50px",
                    border: "1px solid green",
                  }}
                >
                  {" "}
                  50sqft
                </div>
              </div>

              <div
                style={{
                  width: "200px",
                  height: "100px",
                  border: "1px solid green",
                }}
              >
                200sqft
              </div>
              <div
                style={{
                  width: "200px",
                  height: "100px",
                  border: "1px solid green",
                  backgroundColor: "red",
                }}
              >
                {" "}
                200sqft - Arrears
              </div>
              <div
                style={{
                  width: "200px",
                  height: "50px",
                  backgroundColor: "grey",
                }}
              ></div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: "100px",
                    height: "100px",
                    border: "1px solid green",
                  }}
                >
                  100sqft
                </div>
                <div
                  style={{
                    width: "100px",
                    height: "100px",
                    border: "1px solid green",
                  }}
                >
                  {" "}
                  100sqft
                </div>
              </div>
            </div>
            <div
              style={{
                width: "250px",
                height: "400px",
                border: "1px solid green",
              }}
            >
              1000sqft
            </div>
            <div
              style={{
                width: "183.6667px",
                height: "400px",
                border: "1px solid green",
              }}
            >
              600sqft
            </div>
          </div>
          <div style={{ position: "relative", top: "-300px" }}>
            {/* center section */}
            <div style={{ display: "flex" }}>
              <div>
                <div
                  style={{
                    width: "100px",
                    height: "100px",
                    border: "1px solid green",
                  }}
                >
                  100sqft
                </div>
                <div
                  style={{
                    width: "100px",
                    height: "100px",
                    border: "1px solid green",
                  }}
                >
                  {" "}
                  100sqft
                </div>
              </div>
              <div
                style={{
                  width: "50px",
                  height: "200px",
                  backgroundColor: "grey",
                }}
              ></div>

              <div>
                <div
                  style={{
                    width: "66.6667px",
                    height: "75px",
                    border: "1px solid green",
                  }}
                >
                  50sqft
                </div>
                <div
                  style={{
                    width: "66.6667px",
                    height: "75px",
                    border: "1px solid green",
                  }}
                >
                  {" "}
                  50sqft
                </div>
                <div
                  style={{
                    width: "66.6667px",
                    height: "50px",
                    backgroundColor: "grey",
                  }}
                ></div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      border: "1px solid green",
                    }}
                  >
                    {" "}
                    25sqft
                  </div>
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      border: "1px solid green",
                    }}
                  >
                    25sqft
                  </div>
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      border: "1px solid green",
                    }}
                  >
                    25sqft
                  </div>
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      backgroundColor: "grey",
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    width: "100px",
                    height: "200px",
                    backgroundColor: "grey",
                  }}
                ></div>
              </div>
            </div>
            {/* bottom row */}
            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: "200px",
                  height: "100px",
                  border: "1px solid green",
                }}
              >
                200sqft
              </div>
              <div
                style={{
                  width: "66.667px",
                  height: "100px",
                  border: "1px solid yellow",
                }}
              >
                HOIST
              </div>
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  backgroundColor: "grey",
                }}
              ></div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
