export const Location = () => {
  return (
    <iframe
      id="googleMap"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7341.9154447604715!2d79.88608123547523!3d6.961324585995222!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25842095dafbb%3A0x7b9de5b6afae5677!2sEast%20West%20Self%20Storage!5e0!3m2!1sen!2suk!4v1669468303076!5m2!1sen!2suk"
      width="100%"
      height="450"
      title="Google Map of East West Self Storage"
      style={{ border: "0", marginBottom: "15px" }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
};
