import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import logo from "../../images/logo.svg";

import { Link } from "react-router-dom";

export const NavBar = () => {
  return (
    <Navbar sticky="top">
      <Container>
        <Link to="/" style={{ textDecoration: "none" }}>
          <Navbar.Brand>
            <img
              src={logo}
              className="d-inline-block align-top"
              // width="30"
              height="30"
              alt="logo"
            />
            {" storage.lk"}
          </Navbar.Brand>
        </Link>
        <Nav className="justify-content-end">
          {/* <Button style={{ padding: "5px" }} type="link">
            <Link
              style={{
                color: "salmon",
                textDecoration: "none",
                fontSize: "18px",
              }}
              to="/talent"
            >
              talentDB
              <sup
                style={{
                  margin: "0px 5px 0px",
                  fontSize: "12px",
                  backgroundColor: "white",
                  color: "salmon",
                }}
              >
                pro
              </sup>
            </Link>
          </Button> */}
        </Nav>
      </Container>
    </Navbar>
  );
};
