import { Col, Divider, Row, Space } from "antd";
import { Link } from "react-router-dom";
import { CopyRights } from "../../utils/copyRights";

export const Footer = () => {
  return (
    <div>
      <Row gutter={[18, 18]} style={{ margin: "0px 10px" }}>
        <Divider></Divider>
        <Col xs={24} sm={24} md={9}>
          <p style={{ fontWeight: "900" }}>East West Self Storage</p>
          <p style={{ fontSize: "14px" }}>
            Storage.lk is a self storage facility with 200 plus units, located
            in Peliyagoda, Sri Lanka. Unit sizes ranges from 25sqft to
            2000+sqft. Avaliable for short and long term leases.
          </p>
          <Space>
            <a
              style={{
                fontSize: "14px",
                // textDecoration: "none",
                color: "salmon",
              }}
              href={"https://eastwest.lk"}
            >
              {"East West Properties PLC "}
              {/* <img
                style={{ height: "15px" }}
                src={
                  "https://jdevs-app.s3.eu-west-2.amazonaws.com/04-slack-artwork/slack-2.svg"
                }
                alt={"slack logo"}
              /> */}
            </a>
          </Space>
          <p>
            East West Properties PLC is a publically listed on the Colombo Stock
            Exchange. The company owns and managed over 500,000 sqft of
            warehousing and industrial space.
          </p>
        </Col>
        <Col xs={12} sm={12} md={5}>
          <p style={{ fontWeight: "700" }}>Company</p>
          <ul style={{ listStyleType: "none", padding: "0px" }}>
            <li>
              <Link
                style={{
                  fontSize: "14px",
                  textDecoration: "none",
                  color: "salmon",
                }}
                to="/resources/terms-and-conditions"
              >
                Terms of Service
              </Link>
            </li>
            <li>
              <Link
                style={{
                  fontSize: "14px",
                  textDecoration: "none",
                  color: "salmon",
                }}
                to="/resources/terms-and-conditions"
              >
                Resources
              </Link>
            </li>
          </ul>
        </Col>
        <Col xs={12} sm={12} md={5}>
          <p style={{ fontWeight: "700" }}>Community</p>
          <ul style={{ listStyleType: "none", padding: "0px" }}>
            <li>
              <a
                style={{
                  fontSize: "14px",
                  textDecoration: "none",
                  color: "salmon",
                }}
                href={
                  "https://www.linkedin.com/company/east-west-properties-plc/"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Linkedin
              </a>
            </li>
          </ul>
        </Col>
        <Divider style={{ margin: "0px" }}></Divider>
      </Row>
      <CopyRights />
    </div>
  );
};
