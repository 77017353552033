import React, { useState } from "react";
import { Button, Col, Form, Input, Row, Card, Modal } from "antd";
import axios from "axios";
import { WhatsAppOutlined } from "@ant-design/icons";

//size guide images
import size25 from "../../images/size25.png";
import size50 from "../../images/size50.png";
import size75 from "../../images/size75.png";
import size100 from "../../images/size100.png";
import size150 from "../../images/size150.png";
import size200 from "../../images/size200.png";
import size300 from "../../images/size300.png";

export const ContactUs = () => {
  const { TextArea } = Input;
  const [sizeGuide, setSizeGuide] = useState(size25);
  const [form] = Form.useForm();

  const postDiscordMessage = (values) => {
    return axios
      .post(".netlify/functions/postDiscordMessage", {
        values,
      })
      .then((response) => {
        console.log(response);
      })
      .catch(function error(error) {
        const errorMessage = error.response.data;
        console.log(errorMessage);
      });
  };

  const postWhatsAppMessage = (values) => {
    return axios
      .post(".netlify/functions/postWhatsAppMessage", {
        values,
      })
      .then((response) => {
        console.log(response);
      })
      .catch(function error(error) {
        const errorMessage = error.response.data;
        console.log(errorMessage);
      });
  };

  const onFinish = (values) => {
    form.validateFields().then((values) => {
      const success = () => {
        Modal.success({
          content:
            "Thanks for getting in touch with us... Will get in touch ASAP!!",
        });
      };
      postDiscordMessage(values);
      postWhatsAppMessage(values);
      success();
      form.resetFields();
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const SizeGuide = () => {
    return (
      <div className="site-card-border-less-wrapper">
        <Card
          title={"Size Guide"}
          bordered={true}
          style={{ textAlign: "center", margin: "0px", height: "90%" }}
        >
          <Row>
            <Col span={24}>
              <Button
                style={{ marginRight: "5px", marginBottom: "5px" }}
                onClick={() => {
                  setSizeGuide(size25);
                }}
              >
                25sqft
              </Button>
              <Button
                style={{ marginRight: "5px" }}
                onClick={() => {
                  setSizeGuide(size50);
                }}
              >
                50sqft
              </Button>
              <Button
                style={{ marginRight: "5px" }}
                onClick={() => {
                  setSizeGuide(size75);
                }}
              >
                75sqft
              </Button>
              <Button
                style={{ marginRight: "5px" }}
                onClick={() => {
                  setSizeGuide(size100);
                }}
              >
                100sqft
              </Button>
              <Button
                style={{ marginRight: "5px" }}
                onClick={() => {
                  setSizeGuide(size150);
                }}
              >
                150sqft
              </Button>
              <Button
                style={{ marginRight: "5px" }}
                onClick={() => {
                  setSizeGuide(size200);
                }}
              >
                200sqft
              </Button>
              <Button
                style={{ marginRight: "5px" }}
                onClick={() => {
                  setSizeGuide(size300);
                }}
              >
                300sqft
              </Button>
            </Col>
            <br></br>
            <Col md={24}>
              <img width={"100%"} src={sizeGuide} alt={"size guide"} />
            </Col>
          </Row>
        </Card>
      </div>
    );
  };

  const ContactForm = () => {
    return (
      <Card
        title={"Contact Us"}
        bordered={true}
        style={{ textAlign: "center", margin: "0px" }}
      >
        <Form
          name="basic"
          form={form}
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row gutter={18}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label=""
                name="name"
                rules={[{ required: true, message: "Please input your name" }]}
              >
                <Input placeholder="name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label=""
                name="number"
                rules={[
                  {
                    required: true,
                    message: "Please input your contact number!",
                  },
                ]}
              >
                <Input placeholder="Contact Number" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label=""
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input placeholder="email" />
          </Form.Item>
          <Form.Item
            label=""
            name="message"
            rules={[{ required: false, message: "Please input your email!" }]}
          >
            <TextArea placeholder="send us details of your enquiry" rows={4} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
        <p>You can also contact anytime via WhatsApp</p>
        <Button
          href="https://api.whatsapp.com/send?phone=94777748423"
          target={"_blank"}
          rel={"noreferrer"}
          style={{
            backgroundColor: "#16BE45",
          }}
        >
          <span style={{ fontWeight: "500", color: "white" }}>
            <WhatsAppOutlined
              style={{
                verticalAlign: "top",
                fontSize: "20px",
                marginRight: "5px",
                color: "white",
              }}
            />
            {"WhatsApp Us?"}
          </span>
        </Button>
      </Card>
    );
  };

  return (
    <Row
      gutter={[18, 20]}
      style={{ backgroundColor: "whitesmoke", padding: "20px" }}
    >
      <Col xs={24} sm={24} md={12} lg={12}>
        <SizeGuide />
      </Col>
      <Col xs={24} sm={24} md={12} lg={12}>
        <ContactForm />
      </Col>
    </Row>
  );
};
