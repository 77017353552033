import { Button, Col, Row, Space } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export const CopyRights = () => {
  return (
    <Row justify="center" style={{ margin: "10px 0px" }}>
      <Col span={24}>
        <div style={{ textAlign: "center" }}>
          <Space>
            <span style={{ fontSize: "12px" }}>storage.lk &copy; 2023 </span>
            <Button
              style={{
                color: "whitesmoke",
                fontSize: "12px",
                backgroundColor: "black",
                opacity: "70%",
                height: "auto",
                // textDecoration: "none",
              }}
              size="small"
              href="https://techforgood.lk"
              target={"_blank"}
              rel={"noreferrer"}
            >
              <LoadingOutlined />
              {"techForGood.lk"}
            </Button>
          </Space>
        </div>
      </Col>
    </Row>
  );
};
