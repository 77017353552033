export const Terms = () => {
  const keyTerms = [
    {
      title: "Access Hours",
      description: "the hours We permit You to access the Room.",
      link: "",
    },
    {
      title: "This Agreement",
      description: "these terms and conditions and the information set out ",
      link: "",
    },
    { title: "Deposit", description: "the amount specified ", link: "" },
    {
      title: "Due Date",
      description:
        "the date specified for the month of storage and the corresponding date for each following month.",
      link: "",
    },
    {
      title: "The goods",
      description:
        "anything You store in the Room at any time during this Agreement",
      link: "",
    },
    {
      title: "Other Charges",
      description:
        "our fees for the sale of insurance, general merchandise, extended access, transportation, rental of equipment and any TVA payable that may be incurred by You from time to time.",
      link: "",
    },
    {
      title: "Our Fees",
      description:
        "the amount specified overleaf (which does not include TVA) plus any Other Charges which shall also be paid by You where it is or becomes applicable",
      link: "",
    },
    {
      title: "Prompt Payment",
      description:
        " In respect of the payment of each and every sum due under this Agreement, payment on the Due Date or within two working days afterwards, and in respect of any sum being due under any other agreement between You and Us, payment within five days of that sum being demanded in writing",
      link: "",
    },
    { title: "Start Date", description: "the date specified ", link: "" },
    {
      title: "Store",
      description: "the premises in which the Room is situated",
      link: "",
    },
    {
      title: "Termination",
      description:
        "Date the date of termination of this Agreement in accordance with Condition 25 or 26",
      link: "",
    },
    {
      title: "Room",
      description:
        "he storage room specified overleaf or any alternative storage room we may specify under Condition 11",
      link: "",
    },
  ];

  const listKeyTerms = keyTerms.map((term) => {
    return (
      <li>
        {term.title} -{" "}
        {<span style={{ color: "grey" }}>{term.description}</span>}
      </li>
    );
  });
  return (
    <div style={{ margin: "5px" }}>
      <h1>Terms & Conditions</h1>
      <ul>{listKeyTerms}</ul>
      <p>
        1.1. All sums payable under the terms of this Agreement will be
        exclusive of TVA.
      </p>
      <p>
        1.2. All Room sizes are approximate and We accept no responsibility for
        their accuracy. In agreeing to our fees You accept it applies to the
        Room You occupy and not any Room represented.
      </p>
      <p>
        2. So long as Our Fees are paid up to date, we license You, but no other
        person:
      </p>
      <p>
        2.1.1 to Use the Room for the storage of Goods in the Room in accordance
        with this agreement from the Start Date until this Agreement is
        terminated; and
      </p>
      <p>
        2.1.2 to have access to the Room at any time during the Access Hours
        only for the purposes of depositing, removing, substituting or
        inspecting the Goods and Your regular inspection of the Room for damage
        or unsuitability for the Goods. No access to the Room will be permitted
        for any other purposes or outside Access Hours. We will try to provide
        advance warning of changes in Access Hours by notice at the Store, but
        we reserve the right to change Access Hours to other reasonable access
        times at any time without giving any prior notice.
      </p>
      <p>
        2.2 extended access is available between the hours indicated on the
        Customer Storage Agreement. We reserve the right to decline any
        customers request for extended access and to charge You a further
        reasonable charge for the time and effort involved in resolving any
        issues with regard to extended access, when the service has not been
        correctly used by You. We also reserve the right to remove this service
        from you or to adjust the hours of access provided at our absolute
        discretion.
      </p>
      <p>
        3. Only You and persons authorised in writing or accompanied by You
        (“Authorised Persons”) will be allowed to have access to the Room. Any
        such person is Your agent for whose actions You are responsible and
        liable to Us and to other users of Rooms at the Store. You may withdraw
        any authorisation at any time, but the withdrawal will not be effective
        until we receive it in writing. We may ask for proof of identity from
        You or any other person at any time (although We are not obliged by this
        Agreement or otherwise to do so) and We may refuse access to any person
        (including You) who is unable to provide satisfactory proof of identity.
        We may refuse You or Your agents access at any time if We consider in
        our sole discretion that the safety of any person at the Store, or the
        security of the Room or its contents, or other Rooms or their contents
        will be put at risk.
      </p>
      <p>
        4. You are responsible for providing a secure padlock for the Room. You
        must ensure that the Room is locked so as to be secure from unauthorised
        entry at all times when You are not in the Room. We will not be
        responsible for locking any unlocked Room. You are responsible for
        removing the lock from your room upon termination of this agreement. You
        should not leave your key with or permit access to Your Room to any
        person other than Your own agent, who is responsible to You and subject
        to Your control and if You do so, You do so at your own risk whether or
        not such person is our employee or agent. We do not accept any liability
        for any person including our employee or agent holding Your key and
        having access to Your Room and any such person acts as Your agent only.
      </p>
      <p>
        5. You permit Us and Our agents and contractors to enter the Room and if
        necessary we may break the lock to gain entry:-
      </p>
    </div>
  );
};
