import { Card, Col, Row } from "antd";
import security from "../../images/security.svg";
import help from "../../images/help.svg";
import access from "../../images/access.svg";

export const DetailCards = () => {
  return (
    <div className="site-card-wrapper" style={{ margin: "10px" }}>
      <Row
        gutter={[18, 16]}
        style={{ backgroundColor: "whitesmoke", padding: "10px" }}
      >
        <Col xs={24} sm={24} md={8}>
          <Card bordered={true}>
            <Row gutter={18} style={{ height: "100px" }}>
              <Col span={10}>
                <img
                  style={{ width: "120px" }}
                  src={security}
                  alt={"security sheild"}
                />
              </Col>
              <Col span={14}>
                {
                  " The security of our stores and our customers’ possessions are our upmost priority."
                }
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Card bordered={true}>
            <Row gutter={18} style={{ height: "100px" }}>
              <Col span={10}>
                <img
                  style={{ width: "100px" }}
                  src={access}
                  alt={"access key"}
                />
              </Col>
              <Col span={14}>
                {"24/7 Access 365 days, access our storage anytime of the day"}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Card bordered={true}>
            <Row gutter={18} style={{ height: "100px" }}>
              <Col span={8}>
                <img style={{ width: "80px" }} src={help} alt={"help"} />
              </Col>
              <Col span={16}>
                {
                  "Need help? We can recommend mover and transport companies to help you move"
                }
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
