import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { NotFound } from "./utils/notFound";
import { NavBar } from "./components/navigation/navBar";
import { LandingPage } from "./pages/LandingPage";
import { Resources } from "./pages/Resources";
import { Terms } from "./components/resources/terms";
import { RemovalCompanies } from "./components/resources/removalCompanies";
import { ScrollToTop } from "./utils/scrollToTop";
import { StorageLayout } from "./pages/StorageLayout";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <NavBar />
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/layout" element={<StorageLayout />} />
        <Route exact path="/resources" element={<Resources />}>
          <Route path="terms-and-conditions" element={<Terms />} />
          <Route path="removal-companies" element={<RemovalCompanies />} />
        </Route>
        <Route exact path="/*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
