import { Button, Col, Row } from "antd";
import { WhatsAppOutlined } from "@ant-design/icons";

export const Hero = () => {
  return (
    <Row
      style={{
        alignItems: "center",
        justifyContent: "center",
        background: "rgb(225, 225, 219)",
        paddingTop: "15px",
        textAlign: "center",
        backgroundImage: `url(https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-storage-lk/storage-unit-hero.jpeg)`,
        backgroundPosition: "center",
        width: "100%",
        minHeight: "50vh",
        margin: "0px auto",
      }}
    >
      <Col xs={24} sm={24} md={12}>
        <Row>
          <Col span={24}>
            <div
              style={{
                fontFamily: "Visby Round CF DemiBold', sans-serif",
                fontSize: "16px",
                backgroundColor: "whitesmoke",
                opacity: "90%",
                borderRadius: "5px",
              }}
            >
              <h1 style={{ padding: "5px", color: "black" }}>
                Fast. Secure. Convenient. Any Time.
              </h1>
            </div>
            <div
              style={{
                backgroundColor: "whitesmoke",
                fontFamily: "Visby Round CF DemiBold', sans-serif",
                fontSize: "1.1rem",
                padding: "15px",
                opacity: "90%",
                fontWeight: "500",
                borderRadius: "5px",
              }}
            >
              <p>Over 350 storage units across our multi-storey warehouse</p>
              <p>Rates starting from LKR200/sqft</p>
              {/* <div style={{ opacity: "none" }}>
                <Button
                  href="https://wa.me/94777748668"
                  target={"_blank"}
                  rel={"noreferrer"}
                  style={{
                    backgroundColor: "rgb(22, 190, 69)",
                    marginBottom: "5px",
                  }}
                >
                  <span style={{ fontWeight: "500", color: "white" }}>
                    <WhatsAppOutlined
                      style={{
                        verticalAlign: "top",
                        fontSize: "20px",
                        marginRight: "5px",
                        color: "white",
                      }}
                    />
                    {"WhatsApp Us?"}
                  </span>
                </Button>
              </div> */}

              <a
                style={{ textDecoration: "none" }}
                href="tel:+94777748423"
                target={"_blank"}
                rel={"noreferrer"}
              >
                {"+94 777 748 423"}
              </a>
              <a
                style={{ textDecoration: "none" }}
                href="tel:+94112437755"
                target={"_blank"}
                rel={"noreferrer"}
              >
                {" / +94 112 437755"}
              </a>
              <br></br>
            </div>
          </Col>
          <Col span={24} style={{ margin: "10px" }}>
            <div style={{ opacity: "none" }}>
              <Button
                shape="round"
                href="https://api.whatsapp.com/send?phone=94777748423"
                target={"_blank"}
                rel={"noreferrer"}
                style={{
                  backgroundColor: "rgb(22, 190, 69)",
                  marginBottom: "5px",
                }}
              >
                <span style={{ fontWeight: "500", color: "white" }}>
                  <WhatsAppOutlined
                    style={{
                      verticalAlign: "top",
                      fontSize: "20px",
                      marginRight: "5px",
                      color: "white",
                    }}
                  />
                  {"WhatsApp Us?"}
                </span>
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
      {/* <Col xs={24} sm={24} md={12}>
        <div
          style={{
            // background: "rgb(225, 225, 219)",
            margin: "40px",
            textAlign: "center",
          }}
        >
          <img src={heroImage} alt="logo" />
        </div>
      </Col> */}
    </Row>
  );
};
