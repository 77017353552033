export const RemovalCompanies = () => {
  return (
    <div>
      <h1>Removal Companies</h1>
      <ul>
        <li></li>
      </ul>
    </div>
  );
};
