import { Col, Row } from "antd";

export const MatterPort = () => {
  return (
    <Row>
      <Col span={24}>
        <div
          style={{
            justifyContent: "center",
            // display: "flex",
            margin: "15px",
          }}
        >
          <iframe
            width="100%"
            height="480"
            title={`matterport - ${"self storage"}`}
            src="https://my.matterport.com/show/?m=JeQUqTNSXqv"
            frameborder="0"
            allowfullscreen
            allow="xr-spatial-tracking"
          ></iframe>
        </div>
      </Col>
    </Row>
  );
};
